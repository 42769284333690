export default {
  "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End"])},
  "THANKS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you"])},
  "OOPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops"])},
  "UPLOAD": {
    "MESSAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your"])},
    "CAPTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload"])},
    "COUNT": {
      "SINGULAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploaded document"])},
      "PLURAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uploaded documents"])}
    },
    "DOCUMENTS_UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents successfully uploaded"])},
    "MAX_UPLOAD_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum allowed document size :"])},
    "MISSING_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing documents"])},
    "CONTINUE_UPLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue uploading"])},
    "MAX_FILES_LENGTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum allowed number of files per upload: "])}
  },
  "DOCUMENT_TYPE": {
    "BANK_DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank details"])},
    "BANK_STATEMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank statement"])},
    "DISABLED_WORKER_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled worker certificate"])},
    "DRIVING_LICENSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving license"])},
    "ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity document"])},
    "IDENTITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity card"])},
    "KBIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kbis"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
    "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
    "PROOF_OF_ADDRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of address"])},
    "RESIDENCE_PERMIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residence permit"])},
    "SIGNED_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat"])},
    "SOCIAL_SECURITY_CERTIFICATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social security certificate"])},
    "TAX_NOTICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax notice"])},
    "TAX_REPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax report"])},
    "TAX_REPORT_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax report analysis"])},
    "PAYSLIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payslip"])},
    "SELFIE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])}
  },
  "TOAST": {
    "ANALYZING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzing files"])},
    "ANALYZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Files analyzed"])},
    "CONFIGURATION_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration failed"])},
    "TOO_LARGE_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File too large"])},
    "UPLOADING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading"])},
    "UPLOADED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded"])},
    "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred while uploading"])},
    "SEND_OCR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending to analysis"])},
    "OCR_SUCCESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis started"])},
    "OCR_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error occurred while sending to analysis"])},
    "TOO_MANY_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many files uploaded, the maximum is __max__ files"])},
    "TOO_MANY_FILES_ID_CHECK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Too many files uploaded for the identity document, the maximum is 2 files"])}
  },
  "ERROR": {
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred"])},
    "BAD_GATEWAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot connect to the server"])},
    "UNAUTHORIZED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not authorized to access this page"])},
    "NOT_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This page cannot be found"])}
  }
}