import {SERVICE_ROUTE_UPLOAD} from '@/env';
import {MultipartProperties} from "@/types/MultipartProperties";
import {ShortTaskResponseDto, Task, Task2, UploadTaskCauses, UploadTaskResult} from "@/types/Task";

export const UPLOAD_BASE_URL_V2 = `${SERVICE_ROUTE_UPLOAD}/v2/upload/multiple`;
export const UPLOAD_TASK_URL_V2 = `${SERVICE_ROUTE_UPLOAD}/v2/task`;
export const UPLOAD_INFO_URL_V2 = `${SERVICE_ROUTE_UPLOAD}/v2/info`;

export const getMaxRequestSize = (accessToken: string): Promise<MultipartProperties> => {
    return fetch(`${UPLOAD_INFO_URL_V2}/max-request-size?access_token=${accessToken}`)
        .then((response) => response.json());
}

export const getMaxFileSize = (accessToken: string): Promise<MultipartProperties> => {
    return fetch(`${UPLOAD_INFO_URL_V2}/max-file-size?access_token=${accessToken}`)
        .then((response) => response.json());
}

export const uploadMultipleDocuments = (accessToken: string, documentType: string,files: File[]): Promise<Response> => {
    const url = `${UPLOAD_BASE_URL_V2}?documentType=${documentType}&access_token=${accessToken}`;
    const data = new FormData();
    for(let file of files)
        data.append('files', file);
    data.append('documentType', documentType);
    return fetch(url, {
        method: 'POST',
        body: data
    });
}

export const ocr = (accessToken: string): Promise<Response> => {
  const url = `${UPLOAD_BASE_URL_V2}/ocr?access_token=${accessToken}`;
  return fetch(url, {
    method: 'POST'
  });
};

export const getTaskById = (taskId: string, accessToken: string): Promise<ShortTaskResponseDto> => {
    const url = `${UPLOAD_BASE_URL_V2}/task/${taskId}?access_token=${accessToken}`;
    return fetch(url)
        .then(res => res.json());
};

export const getTask = (accessToken: string): Promise<Response> => {
  const url = `${UPLOAD_BASE_URL_V2}/task?access_token=${accessToken}`;
  return fetch(url);
};
