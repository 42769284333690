import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notfound" }

import CircleMessage from "@/components/circleMessage/CircleMessage.vue";
import {translate} from "../i18n";

export default /*@__PURE__*/_defineComponent({
  __name: 'NotFound',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CircleMessage, null, {
      default: _withCtx(() => [
        _createElementVNode("b", null, _toDisplayString(_unref(translate)('OOPS')) + "...", 1),
        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", null, _toDisplayString(_unref(translate)('ERROR.NOT_FOUND')), 1)
      ]),
      _: 1
    })
  ]))
}
}

})